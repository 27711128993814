<template>
  <v-card elevation="0" :loading="loading" style="min-height: 90px;">
    <v-card-title class="pa-0">
      <v-tabs v-model="tab" height="56" color="primary" background-color="grey lighten-4">
        <v-tab class="body-2 font-weight-bold text-capitalize">
          {{ $t("dashboard.about.about") }}
        </v-tab>
        <v-tab class="body-2 font-weight-bold text-capitalize">
          {{ $t("dashboard.about.mission") }}
        </v-tab>
        <v-tab class="body-2 font-weight-bold text-capitalize">
          {{ $t("dashboard.about.vision") }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="pa-2">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <p class="ma-0" v-html="organizationDetail.about" />
          <p v-if="loading" class="text-center ma-0">
            {{ $t("global.loading_message") }}
          </p>
          <p v-else-if="!organizationDetail.about" class="text-center ma-0">
            {{ $vuetify.lang.t("$vuetify.noDataText") }}
          </p>
        </v-tab-item>
        <v-tab-item>
          <p class="ma-0" v-html="organizationDetail.mission" />
          <p v-if="loading" class="text-center ma-0">
            {{ $t("global.loading_message") }}
          </p>
          <p v-else-if="!organizationDetail.mission" class="text-center ma-0">
            {{ $vuetify.lang.t("$vuetify.noDataText") }}
          </p>
        </v-tab-item>
        <v-tab-item>
          <p class="ma-0" v-html="organizationDetail.vision" />
          <p v-if="loading" class="text-center ma-0">
            {{ $t("global.loading_message") }}
          </p>
          <p v-else-if="!organizationDetail.vision" class="text-center ma-0">
            {{ $vuetify.lang.t("$vuetify.noDataText") }}
          </p>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
  import { ORGANIZATION_DETAIL } from "../query";

  export default {
    name: "About",
    data: vm => ({
      organizationDetail: {},
      loading: false,
      tab: 0
    }),
    methods: {
      fetchItems() {
        const options = {
          query: ORGANIZATION_DETAIL,
          fetchPolich: "no-cache"
        };
        this.loading = true;
        this.$apollo
          .query(options)
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.organizationDetail = data.organizationDetail;
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style></style>
